import { css } from '@emotion/core'

export default () => css`
.content-slider-section,
.product-slider-section {
    padding: 70px 0;
    background: #DDCFC3;
}

.content-slider-section .section-container,
.product-slider-section .section-container {
    padding: 0 16px;
    position: relative;
}

.content-slider-section .content-slider-header,
.product-slider-section .content-slider-header {
    margin-bottom: 40px;
}

.content-slider-section .slider-section-heading,
.product-slider-section .slider-section-heading {
    color: #231E1E;
    font-family: "Recife";
    font-size: 50px;
    line-height: 48px;
    letter-spacing: -1.5px;
}

.content-slider-section .content-slider-header-description,
.product-slider-section .content-slider-header-description {
    margin-top: 10px;
    color: #231E1E;
    font-size: 14px;
    line-height: 22px;
}
.content-slider-section .section-slider-row,
.content-slider-section .product-section-slider-row,
.product-slider-section .section-slider-row,
.product-slider-section .product-section-slider-row {
    margin-left: -14px;
}

.content-slider-section .section-slider-row.slider-mobile-row .slider-card-image-block,
.content-slider-section .product-section-slider-row.slider-mobile-row .slider-card-image-block,
.product-slider-section .section-slider-row.slider-mobile-row .slider-card-image-block,
.product-slider-section .product-section-slider-row.slider-mobile-row .slider-card-image-block {
    padding: 0;
}

.content-slider-section .section-slider-row.slider-mobile-row .slider-card-image-block .global-image,
.content-slider-section .product-section-slider-row.slider-mobile-row .slider-card-image-block .global-image,
.product-slider-section .section-slider-row.slider-mobile-row .slider-card-image-block .global-image,
.product-slider-section .product-section-slider-row.slider-mobile-row .slider-card-image-block .global-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
        object-fit: cover;
}

.content-slider-section .section-slider-row.slider-mobile-row .slick-dots,
.content-slider-section .product-section-slider-row.slider-mobile-row .slick-dots,
.product-slider-section .section-slider-row.slider-mobile-row .slick-dots,
.product-slider-section .product-section-slider-row.slider-mobile-row .slick-dots {
    display: none !important;
}

.content-slider-section .slick-dotted.slick-slider,
.product-slider-section .slick-dotted.slick-slider {
    margin-bottom: 0;
}

.content-slider-section .slick-slide,
.product-slider-section .slick-slide {
    padding: 0 14px;
}

.content-slider-section .slick-arrow,
.product-slider-section .slick-arrow {
    width: 50px;
    height: 35px;
    top: -64px;
    background: #232323;
    border-radius: 5px;
}

.content-slider-section .slick-arrow:hover,
.product-slider-section .slick-arrow:hover {
    background: #232323;
}

.content-slider-section .slick-arrow::before,
.product-slider-section .slick-arrow::before {
    display: none;
    color: #fff;
}

.content-slider-section .slick-arrow.slick-prev,
.product-slider-section .slick-arrow.slick-prev {
    left: auto;
    right: 68px;
    background: #F4F4F4 url(https://ik.imagekit.io/2fqsvlgz3q/for-living/slider-arrow-left.png?updatedAt=1739445375893) center center no-repeat;
    background-size: 26px auto;
    z-index: 2;
    opacity: 1;
}

.content-slider-section .slick-arrow.slick-next,
.product-slider-section .slick-arrow.slick-next {
    right: 0;
    background: #F4F4F4 url(https://ik.imagekit.io/2fqsvlgz3q/for-living/slider-arrow-right.png?updatedAt=1739445761121) center center no-repeat;
    background-size: 26px auto;
    opacity: 1;
}

.content-slider-section .slick-arrow.slick-disabled,
.product-slider-section .slick-arrow.slick-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.content-slider-section .slick-arrow.slick-disabled::before,
.product-slider-section .slick-arrow.slick-disabled::before {
    opacity: 1;
}
    
.content-slider-section .slick-dots,
.product-slider-section .slick-dots {
    width: 340px;
    height: 6px;
    display: none;
    overflow: hidden;
    left: 50%;
    bottom: -50px;
    background: #F4F4F4;
    border-radius: 9px;
    transform: translateX(-50%);
}
    
.content-slider-section .slick-dots li,
.product-slider-section .slick-dots li {
    width: 100%;
    height: auto;
    margin: 0;
}

.content-slider-section .slick-dots li.slick-active button,
.product-slider-section .slick-dots li.slick-active button {
    background: #943A3A;
}

.content-slider-section .slick-dots li button,
.product-slider-section .slick-dots li button {
    width: 100%;
    height: 6px;
    margin: 0;
    padding: 0;
    background: #fff;
}

.content-slider-section .slick-dots li button::before,
.product-slider-section .slick-dots li button::before {
    display: none;
}

@media (max-width: 767px) {
    .content-slider-section,
    .product-slider-section {
        padding: 40px 0 100px;
    }

    .content-slider-section .section-container,
    .product-slider-section .section-container {
        padding-left: 24px;
        padding-right: 0;
    }

    .content-slider-section .content-slider-header,
    .product-slider-section .content-slider-header {
        margin-bottom: 24px;
    }

    .content-slider-section .slider-section-heading,
    .product-slider-section .slider-section-heading {
        font-size: 35px;
        line-height: 37px;
        letter-spacing: -1px;
    }

    .content-slider-section .content-slider-section .section-slider-row.slider-mobile-row .slick-dots,
    .product-slider-section .content-slider-section .section-slider-row.slider-mobile-row .slick-dots {
        display: flex !important;
    }

    .content-slider-section .slick-arrow,
    .product-slider-section .slick-arrow {
        top: auto;
        bottom: -70px;
        transform: translate3d(0, 0, 0);
    }

    .content-slider-section .slick-arrow.slick-prev,
    .product-slider-section .slick-arrow.slick-prev {
        right: 52%;
    }

    .content-slider-section .slick-arrow.slick-next,
    .product-slider-section .slick-arrow.slick-next {
        left: 52%;
    }

    .content-slider-section .slick-slider .slick-list,
    .product-slider-section .slick-slider .slick-list {
        padding-left: 0 !important;
    }

    .content-slider-section .slick-slide,
    .product-slider-section .slick-slide {
        padding: 0 16px;
    }

    .content-slider-section .slick-dots,
    .product-slider-section .slick-dots {
        display: none !important;
        width: 70%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .content-slider-section,
    .product-slider-section {
        padding: 60px 0 100px;
    }

    .content-slider-section .section-container,
    .product-slider-section .section-container {
        padding-left: 40px;
    }

    .content-slider-section .slick-slide,
    .product-slider-section .slick-slide {
        padding: 0 16px;
    }

    .content-slider-section .slick-arrow,
    .product-slider-section .slick-arrow {
        top: auto;
        bottom: -70px;
        transform: translate3d(0, 0, 0);
    }

    .content-slider-section .slick-arrow.slick-prev,
    .product-slider-section .slick-arrow.slick-prev {
        right: 52%;
    }

    .content-slider-section .slick-arrow.slick-next,
    .product-slider-section .slick-arrow.slick-next {
        left: 52%;
    }

    .content-slider-section .slick-dots,
    .product-slider-section .slick-dots {
        width: 70%;
        display: none !important;
    }
}

@media (min-width: 1024px) and (max-width: 1399px) {
    .content-slider-section .section-container-1140,
    .product-slider-section .section-container-1140 {
        padding-left: 50px;
    }
}

.product-slider-section {
    padding-bottom: 110px;
    background: #95B3C1;
}

.slider-card {
  width: 250px;
}

.product-slider-section .slick-dots {
  display: flex;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/* Slider */
.slick-loading .slick-list {
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick1';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}

.slick-prev:before {
    content: '←';
}

[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
    right: -25px;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}

.slick-next:before {
    content: '→';
}

[dir='rtl'] .slick-next:before {
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}

.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}

/*content card style*/
.slider-card {
    width: 250px;
}

.slider-card.category-card {
    padding: 0;
}

.slider-card.category-card .slider-card-image-block {
    padding: 0;
}

.slider-card.category-card .global-image {
    -o-object-fit: cover;
        object-fit: cover;
}

.slider-card .slider-card-image-block {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    background: #fff;
    border-radius: 15px;
}

.product-slider-section .slider-card-image-block {
    width: 282px;
    height: 338px;
    background: #fff;
    display: -moz-flex;
    display: flex;
    -moz-flex-direction: row;
    flex-direction: row;
    flex-wrap: nowrap;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-content: stretch;
    -moz-content: stretch;
    -ms-content: stretch;
    content: stretch;
}

.slider-card .slider-card-image-block .content-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}

.slider-card .slider-card-image-block .global-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
        object-fit: cover;
}

.slider-card .slider-card-image-block .global-image.product-image {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    -o-object-fit: unset;
        object-fit: unset;
}

.slider-card .slider-card-title {
    margin-top: 25px;
    color: #231E1E;
    font-family: "Recife";
    font-size: 22px;
    line-height: 29px;
}
.slider-card .slider-card-user,
.slider-card .slider-card-user-link {
    margin-top: 10px;
    color: #191919;
    font-family: "Recife";
    font-size: 17px;
    letter-spacing: 2px;
}

.slider-card .slider-card-description,
.slider-card .slider-card-shop {
    margin-top: 6px;
    color: #231E1E;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.64px;
}

.content-slider-section .slider-card-description {
  margin-top: 10px;
}

.slider-card .slider-card-shop {
    margin-top: 0;
}

.slider-card .slider-card-price {
    margin-top: 6px;
    color: #231E1E;
    font-family: "Recife";
    font-size: 17px;
    line-height: 17px;
}

.slider-card .slider-card-link-holder {
    margin-top: 24px;
}

.slider-card .slider-card-price-holder {
    margin-top: 30px;
    color: #191919;
    font-family: "Switzer-Semibold", sans-serif;
    font-size: 17px;
    line-height: 25px;
}

.slider-card .slider-card-link {
    font-size: 14px;
    text-decoration: underline;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.slider-card .slider-card-link:hover {
    color: #231E1E;
    text-decoration: none;
}

.product-slider-section .slider-card-image-block .global-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 15px;
}

.product-slider-section .slider-card-title {
  font-size: 22px;
  line-height: 29px;
}

@media (max-width: 767px) {
    .slider-card .slider-card-image-block {
        height: 300px;
    }

    .slider-card .slider-card-title {
        font-size: 18px;
    }
    
    .slider-card .slider-card-description {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.5px;
    }
    
    .slider-card .slider-card-link-holder {
        margin-top: 20px;
    }
    
    .slider-card .slider-card-link {
        font-size: 12px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .slider-card .slider-card-image-block {
        height: 300px;
    }
    
    .slider-card .slider-card-title {
        font-size: 18px;
    }
    
    .slider-card .slider-card-description {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.5px;
    }
    
    .slider-card .slider-card-link-holder {
        margin-top: 20px;
    }
    
    .slider-card .slider-card-link {
        font-size: 12px;
    }
}
`
