import { css } from '@emotion/core'

export default () => css`
.hero-banner {
  width: 100%;
  height: 730px;
  background-repeat: no-repeat;
  background-size: cover;
  display: -moz-flex;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.hero-banner.product-header {
  width: 100%;
  height: 884px;
  background-repeat: no-repeat;
  background-size: cover;
  display: -moz-flex;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}

.hero-banner .product-header-logo {
  max-width: 280px;
  max-height: 72px;
}
  
.hero-banner .banner-container {
  width: 1264px;
  margin: 0 auto;
  padding: 0 60px;
}
  
.hero-banner .banner-content-section {
  width: 400px;
  color: #fff; 
}

.hero-banner .banner-title {
  margin-bottom: 16px;
}

.hero-banner .banner-heading {
  margin-bottom: 20px;
  color: #DDCFC3;
  font-family: "Recife";
  font-size: 55px;
  line-height: 64px;
  letter-spacing: -1.65px;
}

.hero-banner .banner-header-title {
  margin-bottom: 20px;
  color: #DDCFC3;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.64px;
}
  
@media (max-width: 767px) {
  .hero-banner {
    height: 680px;
    padding-bottom: 42px;
  }
      
  .hero-banner .banner-container {
    width: 100%;
    padding: 0;
  }
      
  .hero-banner .banner-content-section {
    width: 100%;
    padding: 0 25px;
  }

  .hero-banner .banner-title {
    margin-bottom: 14px;
  }
      
  .hero-banner .banner-heading {
    margin-bottom: 14px;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: -1.35px;
  }
      
  .hero-banner .banner-header-title {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.56px; 
  } 
}

@media (min-width: 768px) and (max-width: 1023px) {
  .hero-banner {
    padding: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .hero-banner {
    padding: 0 40px;
    background-position: right center;
  } 
}

.image-content-section {
  padding: 60px 0;
  color: #DDCFC3;
  background: #943A3A;
}

.image-content-section.reverse-content .section-content-holder {
  -moz-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
  
.image-content-section .section-content-holder {
  width: 100%;
  padding: 0 16px;
  display: -moz-flex;
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.image-content-section .section-image-block {
  width: 680px;
  height: 510px;
  overflow: hidden;
  border-radius: 15px;
}

.image-content-section .section-content-block {
  width: calc(100% - 680px);
  padding: 0 70px 0 30px;
}

.image-content-section .content-tag-text {
  margin-bottom: 10px;
}

.image-content-section .section-title {
  font-family: "Recife";
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1.2px;
}

.image-content-section .section-description {
  margin-top: 20px;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.64px;
}
  
.image-content-section .section-button-row {
  margin-top: 30px;
}
  
@media (max-width: 767px) {
  .image-content-section {
    padding: 24px 0 40px;
  }
      
  .image-content-section .section-image-block {
    width: 100%;
    height: 250px;
  }

  .image-content-section .section-image-block .global-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
        object-fit: cover;
  }
      
  .image-content-section .section-content-holder {
    padding: 0;
  }
      
  .image-content-section .section-content-block {
    width: 100%;
    padding-top: 22px;
    padding-right: 25px;
    padding-left: 0;
  }
      
  .image-content-section .content-tag-text {
    margin-bottom: 22px;
  }
      
  .image-content-section .section-title {
    font-size: 30px;
    line-height: 38px;
    line-spacing: -0.9px;
  }
      
  .image-content-section .section-description {
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.56px;
  }
      
  .image-content-section .section-button-row {
    margin-top: 30px;
  }
}
  
@media (min-width: 768px) and (max-width: 1023px) {
  .image-content-section {
    padding: 40px 0;
  }
  
  .image-content-section .section-image-block {
    width: 280px;
    height: 300px;
  }
  
  .image-content-section .section-content-block {
    width: calc(100% - 280px);
    padding-left: 30px;
  }
      
  .image-content-section .section-title {
    font-size: 30px;
    line-height: 38px;
  }
      
  .image-content-section .section-description {
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
      
  .image-content-section .section-button-row {
    margin-top: 30px;
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .image-content-section {
    padding: 40px;
  }
  
  .image-content-section .section-content-block {
    padding-left: 80px;
  }
}


`
